import React, { useEffect, useState } from "react";
import { Button, Dialog, TextField } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { update_Token } from "../../redux/actions/nodeAction";
import { TOKEN_ENDPOINT } from "../../utils/config";
const InviteMemberDlg = ({
  open,
  onClosed,
  onConfirm,
  onAddMember,
  inviteMembers,
  errText,
  isValidEmail,
  onHandleChange,
  onRemoveMember,
  onDropvalue,
  Maptype,
}) => {
  const [Inviteemail, setInvite_email] = useState(true);
  const [openRoll, openRollmodel] = useState(null);
  const [Inviteemail_class, setInvite_email_class] = useState("invite-title");
  const [Invitelink_class, setInvite_link_class] = useState("");
  const { selectedMapData } = useSelector((state) => state.node);
  const [setdrop, setdropvalue] = useState("Editor");
  const [isCopyLink, setCopyLink] = useState(false);
  const [isLinkCopied, setCopy] = useState(false);
  const [isSendInvitations, setSendInvitations] = useState("Send Invitations");
  const dispatch = useDispatch();
  // const onKeyEventHandler = (evt) => {
  //   if (evt.key === "Escape") {
  //     onClosed();
  //   }
  // };
  useEffect(() => {
    if (Maptype === "Template") {
      setInvite_email(false);
    }
  }, [Maptype]);
  useEffect(() => {
    const onKeyEventHandler = (evt) => {
      if (evt.key === "Escape") {
        onClosed();
      }
    };
    document.addEventListener("keydown", onKeyEventHandler);

    return () => {
      document.removeEventListener("keydown", onKeyEventHandler);
    };
  }, [open, onClosed]);

  const Invite_email = () => {
    setInvite_email(true);
    setInvite_link_class("");
    setInvite_email_class("invite-title");
  };
  const Invite_link = () => {
    setInvite_email(false);
    setInvite_link_class("invite-title");
    setInvite_email_class("");
  };
  const showRollmodel = () => {
    openRoll == null ? openRollmodel("show") : openRollmodel(null);
  };
  const setdropvalues = (value) => {
    setdropvalue(value);
    openRollmodel(null);
  };
  const setdrops = (value) => {
    setdropvalue(value);
    openRollmodel(null);
    dispatch(update_Token(selectedMapData.token, value));
  };
  const SendInvitations = () => {
    // onDropvalue(setdrop)
    setSendInvitations("Sending Invites...");
    onConfirm();
    setTimeout(() => {
      setSendInvitations("Send Invitations");
      onClosed();
    }, 3000);
  };
  const checkEmpty = inviteMembers.filter((item, index) => item.trim() !== "");
  const copyLink = (type) => {
    if (type === "link") {
      setCopy(true);
    } else {
      setCopyLink(true);
    }
    setTimeout(() => {
      setCopyLink(false);
      setCopy(false);
      onClosed();
    }, 3000);
  };
  const style = {
    display: "none",
  };
  return (
    <Dialog
      onClose={onClosed}
      aria-labelledby="customized-dialog-title"
      open={open}
      style={{ margin: "110px 32px 32px 32px" }}
    >
      <div className="solid">
        {Maptype === "Template" ? (
          <span
            className="invite-title"
            style={{ marginBottom: "-1px", cursor: "pointer" }}
          >
            Share your template
          </span>
        ) : (
          <>
            <span
              className={`${Inviteemail_class} mr-3`}
              style={{ marginBottom: "-1px", cursor: "pointer" }}
              onClick={Invite_email}
            >
              Invite by email
            </span>
            <span
              className={`${Invitelink_class} ml-3`}
              style={{ marginBottom: "-1px", cursor: "pointer" }}
              onClick={Invite_link}
            >
              Invite with link
            </span>
          </>
        )}
      </div>
      {Inviteemail ? (
        <div className="invite-dlg">
          <h3 className="invite-dlg-title">
            Each recipient will get emailed instructions <br />
            to access this map as an
            <div className="dropdown">
              <span className="space" onClick={() => showRollmodel(this)}>
                {" "}
                {setdrop} <i className="fas fa-chevron-down"></i>
              </span>
              <div className={`dropdown-content ${openRoll}`}>
                <a
                  href={() => false}
                  onClick={() => {
                    setdropvalues("Observer");
                  }}
                >
                  Observer{" "}
                  <i className="far fa-question-circle tooltip">
                    <span className="tooltiptext">Can see the map</span>
                  </i>
                </a>
                <a
                  href={() => false}
                  onClick={() => {
                    setdropvalues("Editor");
                  }}
                >
                  Editor{" "}
                  <i className="far fa-question-circle tooltip">
                    <span className="tooltiptext">Can edit the map</span>
                  </i>
                </a>
              </div>
            </div>
          </h3>
          <div className="max_to">
            {inviteMembers.map((member, ind) => (
              <div className="display" key={ind.toString()}>
                <TextField
                  className="input-form"
                  label="Enter email address"
                  key={ind}
                  value={member}
                  error={
                    errText && isValidEmail.filter((p) => p.email === member)[0]
                  }
                  helperText={
                    errText && isValidEmail.filter((p) => p.email === member)[0]
                      ? isValidEmail.filter((p) => p.email === member)[0].error
                      : ""
                  }
                  style={{ color: "#000" }}
                  onChange={(event) => onHandleChange(event, ind)}
                />
                {inviteMembers.length !== 1 ? (
                  <i
                    className="fas fa-times-circle"
                    onClick={() => onRemoveMember(ind)}
                  ></i>
                ) : (
                  ""
                )}
              </div>
            ))}
          </div>
          <Button className="add-collaborator" onClick={onAddMember}>
            + Invite Another
          </Button>
          <div className="button-container-div">
            <Button
              variant="contained"
              className="btn-invite"
              onClick={() => SendInvitations()}
              onclick={onDropvalue(setdrop)}
              disabled={
                inviteMembers.length !== checkEmpty.length ? true : false
              }
            >
              {isSendInvitations}
            </Button>
          </div>
        </div>
      ) : (
        <div className="invite-dlg">
          <h3 className="invite-dlg-title">
            {Maptype === "Template"
              ? "Share this link to allow others to use your template"
              : "Anyone with the link will join as an"}
            <div
              className="dropdown"
              style={Maptype === "Template" ? style : {}}
            >
              <span className="space" onClick={() => showRollmodel(this)}>
                {" "}
                {setdrop} <i className="fas fa-chevron-down"></i>
              </span>
              <div className={`dropdown-content ${openRoll}`}>
                <a
                  href={() => false}
                  onClick={() => {
                    setdrops("Observer");
                  }}
                >
                  Observer{" "}
                  <i className="far fa-question-circle tooltip">
                    <span className="tooltiptext">Can see the map</span>
                  </i>
                </a>
                <a
                  href={() => false}
                  onClick={() => {
                    setdrops("Editor");
                  }}
                >
                  Editor{" "}
                  <i className="far fa-question-circle tooltip">
                    <span className="tooltiptext">Can edit the map</span>
                  </i>
                </a>
                {/* <a onClick={()=>{setdrops("Owner")}}>Owner <i className="far fa-question-circle tooltip"><span className="tooltiptext">Can edit, duplicate and delete the map and manage collaborators</span></i></a> */}
              </div>
            </div>
          </h3>
          {selectedMapData && selectedMapData.token && (
            <CopyToClipboard
              text={`${TOKEN_ENDPOINT}${
                Maptype === "Template" ? "/template/" : "/join/"
              }${selectedMapData?.token}-${selectedMapData?.name
                .replace(/[^a-zA-Z ^0-9]/g, "")
                .trim()
                .replace(/ /g, "-")}`}
            >
              <p
                className="link"
                onClick={() => copyLink("link")}
              >{`${TOKEN_ENDPOINT.toLowerCase()}${
                Maptype === "Template" ? "/template/" : "/join/"
              }${selectedMapData?.token}-${selectedMapData?.name
                .replace(/[^a-zA-Z ^0-9]/g, "")
                .trim()
                .replace(/ /g, "-")}`}</p>
            </CopyToClipboard>
          )}
          <div className="button-container-div center">
            {selectedMapData && selectedMapData.token && (
              <CopyToClipboard
                text={`${TOKEN_ENDPOINT}${
                  Maptype === "Template" ? "/template/" : "/join/"
                }${selectedMapData?.token}-${selectedMapData?.name
                  .replace(/[^a-zA-Z ^0-9]/g, "")
                  .trim()
                  .replace(/ /g, "-")}`}
              >
                <Button
                  variant="contained"
                  className="btn-invite"
                  onClick={() => copyLink("button")}
                >
                  {isLinkCopied
                    ? "Link copied to clipboard"
                    : isCopyLink
                    ? "Link copied to clipboard"
                    : `Copy  ${
                        Maptype === "Template" ? "Template" : "Map"
                      } Link`}
                </Button>
              </CopyToClipboard>
            )}
          </div>
        </div>
      )}
      {selectedMapData && selectedMapData.tag === "starter" ? (
        <div className="solid_bottom">
          <span className="mt-3">
            Recipients will need to sign in to access this map.
            <br />
          </span>
          <span className="mb-3">
            <a
              href="https://cauzality.com/plans/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Upgrade
            </a>{" "}
            to invite guests (no sign in required).
          </span>
        </div>
      ) : (
        ""
      )}
    </Dialog>
  );
};

export default InviteMemberDlg;
