import { UPDATE_USER_ACTION, SAVE_SELECTED_ACTION_ID } from "../actionTypes";
import { userActionsRef } from "../../core/firebase";

export const getTokenMovements = () => (dispatch, getState) => {
  userActionsRef.onSnapshot((querySnapshot) => {
    const res = [];
    querySnapshot.forEach((doc) => {
      const subAction = {
        ...doc.data(),
        id: doc.id,
      };
      res.push(subAction);
      if (
        subAction.userName === getState().auth.displayName &&
        !getState().movement.selectedActionId
      ) {
        dispatch(saveSelectedActionId(doc.id));
      }
    });
    dispatch(saveMovements(res));
  });
};
export const getMovements = () => (dispatch, getState) => {
  userActionsRef.onSnapshot((querySnapshot) => {
    if (
      (getState().auth && getState().auth.email) ||
      sessionStorage.getItem("guestLogin")
    ) {
      const res = [];
      querySnapshot.forEach((doc) => {
        const subAction = {
          ...doc.data(),
          id: doc.id,
        };
        res.push(subAction);
        var checkName = sessionStorage.getItem("guestLogin")
          ? sessionStorage.getItem("guestLogin")
          : getState().auth.displayName;
        if (
          subAction.userName === checkName &&
          !getState().movement.selectedActionId
        ) {
          dispatch(saveSelectedActionId(doc.id));
        }
      });
      dispatch(saveMovements(res));
    }
  });
};

export const saveMovements = (actionsData) => ({
  type: UPDATE_USER_ACTION,
  payload: actionsData,
});

export const saveSelectedActionId = (id) => ({
  type: SAVE_SELECTED_ACTION_ID,
  payload: id,
});

export const uploadMovement = (action) => async (dispatch, getState) => {
  if (!getState().movement.selectedActionId) {
    userActionsRef
      .add({ ...action, userName: action?.userName || "" })
      .then((addedDoc) => {
        addedDoc.update({ id: addedDoc.id });
        dispatch(saveSelectedActionId(addedDoc.id));
      });
  } else {
    const docRef = userActionsRef.doc(getState().movement.selectedActionId);
    docRef.set({
      ...action,
      userName: action?.userName || "",
      id: getState().movement.selectedActionId,
    });
  }
};

export const deleteMouseMovement = (id) => (dispatch, getState) => {
  const selectedActionId = id || getState().movement.selectedActionId;
  if (selectedActionId) {
    const docRef = userActionsRef.doc(selectedActionId);
    docRef.delete();
    dispatch(saveSelectedActionId(null));
  }
};
